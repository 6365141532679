import processLayersStore from "@ggk_module/store/processLayers";
import checkAppMapStore from "@ggk_module/store/checkAppMap";
import pageAccessStore from "@ggk_module/store/pageAccess";
import models3dStore from "@ggk_module/store/models3d";
import cesiumStore from "@ggk_module/store/cesium";
export default function ({ store }) {
  store.registerModule('processLayers', processLayersStore)
  store.registerModule('checkAppMap', checkAppMapStore)
  store.registerModule('pageAccess', pageAccessStore)
  store.registerModule('models3d', models3dStore)
  store.registerModule('cesium', cesiumStore)

  store.commit("setBaseHeaderMenuItem", {
    to: "/base/help",
    name: "ggk_module@help.title",
  });

  store.commit("setArmHeaderMenuItem", {
    to: "/arm/analytics",
    name: "Аналитика",
  });
  store.commit("setArmUserMenuItem", {
    to: "/arm/admin/system/registryAccess/list",
    name: "ggk_module@registryAccess.title",
    condition: user => user?.is_system_admin
  });

  store.commit("setArmUserMenuItem", {
    to: "/arm/admin/system/technicalWorks",
    name: "ggk_module@technicalWorks.link",
    condition: user => user?.is_system_admin
  });
}
